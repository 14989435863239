.link-internal {
  &:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f14c";
    padding-right: 0.5rem;
  }
}

.link-external {
  &:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f08e";
    padding-right: 0.5rem;
  }
}

.link-download {
  &:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f019";
    padding-right: 0.5rem;
  }
}

.oc-anchor-navigation {
  a {
    color: $body-color;
    &.active {
      font-weight: 600;
      color: $primary;
    }
  }
}