.carousel-indicators {
  position: absolute;
  z-index: 100;
  right: inherit;
  left: rem-calc(23);
  bottom: 1.5rem;
  margin: inherit;
}

.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #000;
  opacity: 1;

  &.active {
    background-color: $primary;
  }
}

.carousel-control-prev, .carousel-control-next {
  display: none;
}