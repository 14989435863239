.oc-form-col {
  float: left;
  padding: 0 5px;
}

.oc-form-col-center {
  width: 100%;
  text-align: center;
}

.oc-form-col-33 {
  width: 33%;
  @include media-breakpoint-down(md) {
    width: 50%;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.oc-form-col-50 {
  width: 50%;
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.oc-form-col-100 {
  width: 100%;
}

ul.parsley-errors-list {
  margin: -1rem 0 1rem 0;
  padding: 0;
  li {
    list-style: none;
  }
  color: red;
  font-size: 0.9rem;
  font-style: italic;
}