.oc-news-element {

  h2 {
    color: $body-color;
    font-size: 1.875rem;
  }

  border: 5px solid $oc-grey-lightest;
  background: inherit;

  &:hover {
    background: #fff;
  }

  background: $oc-grey-lightest;
  padding: 2rem;
}

.badge {
  color: #fff;

  &.oc-news-category {

    margin-right: 5px;

    &.main, &.even {
      background: $oc-blue;
    }

    &.other, &.odd {
      background: $oc-blue-light;
    }
  }

}

.oc-news-show-all {
  color: $primary;
  a:hover {
    text-decoration: none;
    color: $primary;
  }
}

.oc-news-element-detail {
  &:hover {
    background: $oc-grey-lightest;
  }
}

.oc-news-element-authorname {
  color: $oc-blue-light;
  a {
    color: $oc-blue-light;
  }
}

.oc-news-element-image-slider-wrapper {
  img {
    max-width: 100%;
    padding: 2%;
  }
  background: #fff;
}

.oc-news-item-hr {
  border-top: 1px solid $oc-grey;
}

.oc-news-element-socialicons, .oc-news-element-print {
  font-size: 1.25rem;
  a {
    color: $oc-grey;
  }
  i {
    margin-right: 0.25rem;
  }
}

.oc-read-more-toggle {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  background: url(../Images/show-all-items.png) no-repeat;
  &:hover {
    cursor: pointer;
    background: url(../Images/show-all-items-hover.png) no-repeat;
  }
}

.oc-news-element-details, .oc-news-list-of-definitions {
  h3 {
    font-size: 1.563rem;
    color: $oc-blue;
    font-weight: 500;
  }
}