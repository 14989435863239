@media print {
  .navbar,
  .dropdown,
  .oc-breadcrumb,
  .oc-page-footer,
  .oc-to-top,
  .oc-nav-mobile,
  .oc-navbar-toogle,
  .oc-navbar-transparent,
  .oc-dau-menu-text,
  .oc-news-search-form,
  .oc-news-element-socialicons,
  .oc-news-element-print,
  .oc-event-search-form,
  .oc-event-item-header,
  .oc-read-more-toggle,
  .oc-event-item-map,
  .oc-quote-icon,
  .oc-header-waves-wrapper,
  .oc-schedule-slider,
  .oc-become-part,
  .coordination-board-archive {
    display: none;
  }
  .collapse {
    display: block !important;
  }
  main {
    img {
      margin-bottom: 1rem;
      width: 400px;
      height: auto;
    }

    .oc-testimonial-image, .oc-quote-image, .coordination-board-member-image {
      img {
        width: 200px;
        height: auto;
      }
    }
  }
  .shadow {
    box-shadow: none !important;
  }
}