.oc-to-top {
  display: none;
  position: fixed;
  z-index:9999;
  color: $primary;
  bottom: 1rem;
  right: 1rem;
  &:hover {
    cursor: pointer;
  }
  .fa-stack {
    font-size: 1.5rem;
  }
}