.page-navigation {
  p {
    float: left;
  }

  ul {
    float: right;

    li {
      float: left;
      display: block;
      padding: 0 3px;
      background: none;

      a {
        display: block;
      }
    }
  }
}

ul.f3-widget-paginator {
  margin: 0;
  padding: 0;
}