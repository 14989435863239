.oc-copyright-wrapper {
  position: relative;
}

.oc-copyright {
  color: #fff !important;
  font-style: italic;
  font-weight: 300;
  font-size: 0.9rem;
  position: absolute;
  bottom: 10px;
  right: 10px;
  &:before {
    content: '\00a9';
  }
}