.oc-news-search-form {
  background: $oc-grey-light;
  @include media-breakpoint-down(md) {
    select {
      margin-bottom: 0.5rem;
    }
    .oc-button-search {
      margin-top: 1rem;
    }
  }
}

.oc-button-search {
  padding: 0.25rem 1.2rem;
}