.oc-statute {
  h2 {
    font-size: 1.5625rem;
    color: $oc-blue-light;
  }

  h3 {
    font-size: 1.25rem;
    color: $body-color;
    padding-bottom: rem-calc(2);
  }
}

.oc-section-signs {
  li {
    padding: 0;
    margin: 0;
    display: flex;
    padding-bottom: 1rem;

    strong {
      margin-right: 1rem;
    }

  }
}

@include media-breakpoint-down(md) {
  .oc-anchor-navigation {
    display: none;
  }
}