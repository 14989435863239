.oc-promokit-item {

  img {
    width: 150px;
    height: auto;
  }

  color: #fff;

  a {
    color: #fff;
  }

  &.even {
    background: $oc-blue;
  }

  &.odd {
    background: $primary;
  }
}




