// my variables
$oc-green1: #1ca350;
$oc-green2: #1ca350;
$oc-green-light: #b1e0af;

$oc-blue: #3395cd;
$oc-blue-light: #4cace3;
$oc-blue-lighter: #82c5ec;
$oc-blue-lightest: #edf7fc;

$oc-black: #333;
$oc-white: #fff;

$oc-grey: #707070;
$oc-grey-light: #f5f5f5;
$oc-grey-lightest: #f8f8f8;

// mmenu
$mm_menuWidth: 1.0;
$mm_pagedimOpacity: 0.2;
$mm_pagedimDelay: 0.1s;
$mm_backgroundColor: #fff;
$mm_textColor: #000;


// font awesome paths
$fa-font-path: "../Fonts/fontawesome/webfonts/";

// bootstrap variables
$enable-print-styles: true;

$enable-responsive-font-sizes: true;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1080px,
        xl: 1280px
);

$spacer: 1rem;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 2),
        6: ($spacer * 2.5),
        7: ($spacer * 3),
        8: ($spacer * 3.5),
        9: ($spacer * 4),
);


$primary: $oc-green1;
$font-family-base: 'Raleway';
$font-weight-base: 400;
$line-height-base: 1.75;

$headings-font-weight: 500;
$font-weight-bolder: 500;

$navbar-nav-link-padding-x: 0;
$navbar-light-color: #fff;
$navbar-light-active-color: #fff;
$navbar-light-hover-color: #fff;
$enable-caret: false;

$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .25rem;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-toggler-border-radius: 50%;

$breadcrumb-bg: inherit;
$breadcrumb-divider: quote(">");

$badge-padding-y: 0.4rem;
$badge-padding-x: 1rem;
$badge-border-radius: 0;

$table-head-color: #fff;
$table-head-bg: $oc-blue;
$table-accent-bg: #fff;
$table-cell-padding: 1.5rem;
$table-border-width: 1px;

$btn-padding-y: 1rem;
$btn-padding-x: 3rem;
$btn-border-width: 2px;
$btn-border-radius: 0;

$input-border-radius: 0;