.oc-quotes {
  position: relative;
  width: 55%;
  margin: auto;
  color: #fff;
  background: $oc-grey-light;

  .slick-list {
    img {
      display: inline-block;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.oc-quote-top {
  background: $primary;
  font-style: italic;
}

.oc-quotes-wrapper {
  $oc-quote-size: 100px;

  .oc-quote-icon {
    width: $oc-quote-size;
    height: $oc-quote-size;
  }

  .oc-quote-icon-left {
    position: absolute;
    bottom: -4.2rem;
    left: rem-calc(2);
  }

  .oc-quote-icon-right {
    position: absolute;
    top: -3rem;
    right: rem-calc(2);
  }
}

.oc-quote-information {
  color: $body-color;
}

.oc-quote-image {
  position: relative;
  margin-top: -90px;
}

@include media-breakpoint-down(sm) {
  .slick-arrow {
    display: none !important;
  }
}