
.oc-schedule-slider-element {
  a {
    font-weight: 400;
  }

  a:hover {
    text-decoration: none;
  }

  i {
    margin-right: 5px;
  }

  padding: 2%;

  .inner {
    min-height: 280px;
    padding: 15px;
    color: #fff;
    background: $oc-blue;
  }

  &.odd {
    .inner {
      background: $oc-green1;
    }
  }
}

.oc-schedule-slider-element__day {
  font-size: 1.5rem;
}

.oc-schedule-slider-element__title {
  font-weight: 600;
  margin: 0.75rem 0;
}

@media only screen and (max-width: 680px) {
  .oc-schedule-slider-elements {

    .slick-next, .slick-prev {
      display: none !important;
    }
  }
}