.oc-event-item {
  h2 {
    color: $body-color;
    font-size: 1.875rem;
  }
}

.oc-event-item-text {
  width: 100%;
}

.oc-event-item-header {
  max-height: 350px;
  overflow: hidden;
}

.oc-event-item-read-more-toggle {
  display: inline-block;
  color: $primary;

  &:hover {
    cursor: pointer;
  }
}

.oc-event-item-map-header-icon {
  margin-right: 0.75rem;
}

.oc-event-item-date {
  background: $primary;
}

.oc-ics-export {
  a {
    color: #fff !important;
  }

  @include media-breakpoint-down(md) {
    justify-content: inherit !important;
  }
}

.oc-ics-export-button {
  position: relative;
  top: 1rem;
}

.oc-event-item-date {
  color: #fff;
}

.oc-event-item-date__day {
  font-size: 3.75rem;
}

.oc-event-item-date__monthyear {
  line-height: 1.75rem;
  font-size: 1.5625rem;
}

.oc-event-item-map-header {
  min-height: 110px;
  background: $primary;
  color: #fff;

  p {
    margin: 0;
    padding: 0;

  }
}

.oc-event-item-additional-infos {
  background: $oc-grey-light;
}

.oc-event-item-additional-infos-table {
  td {
    padding: 0 1rem 0 0;
    white-space: nowrap;
  }
}

.oc-event-organizer {
  background: #fff;
}

@include media-breakpoint-down(md) {
  .oc-event-item-map-header-item {
    padding: 1rem;
    width: 50%;
  }
  .oc-event-item-additional-infos {
    .col-md-3 {
      margin-bottom: 1rem;
    }
  }
  .oc-event-item-map-header-icon {
    .fa-3x {
      font-size: 1rem;
    }
  }
  .oc-ics-export-button {
    top: 0;
  }
}