.table {
  margin-top: rem-calc(2);
  background: $oc-blue-lightest;
  th {
    font-weight: 300;
  }
  td {
    border-bottom: none;
    border-top: none;
  }
}