.oc-button-outline {
  color: $primary;
  background-color: #fff;
  font-weight: 600;
}

.oc-button-become-part {
  color: #fff;
  background-color: transparent;
  font-weight: 600;
  border: 2px solid #fff;

  &:hover {
    color: $primary;
    background: #fff;
    border: 2px solid #fff;
  }
}

.oc-button-blue {
  color: $oc-blue;
  background-color: #fff;
  border: 2px solid $oc-blue;

  &:hover {
    background: $oc-blue-light;
    border: 2px solid $oc-blue-light;
  }
}