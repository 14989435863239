.oc-navbar {
  .navbar {
    padding: 30px 0 30px 0;
  }

  height: 140px;
  background: $oc-blue-light;
  font-weight: 500;
  font-size: 1.3rem;

  li {
    margin: 0 0.5rem;
  }

  a.nav-link:hover, a.nav-link.active {
    border-bottom: 2px solid #fff;
    padding-bottom: 2px;
  }
}

.oc-logo {
  position: absolute;
  margin-left: -3.4rem;
  width: 310px;
  height: 150px;
  background: #fff;
  z-index: 888;
  @media only screen and (max-width: 890px) {
    margin-left: inherit;
  }
  @include media-breakpoint-down(sm) {
    width: 230px;
    img {
      width: 200px;
    }
  }
}


.dropdown-menu:before {
  content: " ";
  display: block;
  position: absolute;
  height: 25px;
  top: -25px;
  left: 0;
  right: 0;
}

li.oc-community {
  margin-top: -5px;
  padding: 5px 15px;
  border: 2px solid #fff;

  &:hover {
    background: #fff;

    a.nav-link {
      color: $oc-blue !important;
    }
  }

  a.nav-link:hover {
    border-bottom: 2px solid #fff;
    padding-bottom: 6px;
  }
}

.nav-item.dropdown a:after {
  content: '\f107';
  color: #fff;
  font-family: "Font Awesome 5 Pro";
  padding-left: 5px;
}

.dropdown-menu a:after {
  content: '' !important;
}

