.oc-become-part {
  color: #fff;
  overflow: hidden;
  min-height: 400px;
}

.oc-become-part-content {
  padding: 2rem;
  @include font-size(1.2rem);
  text-align: center;
  h2 {
    font-weight: 800;
    @include font-size(3.25rem);
    color: #fff;
  }
}

h2.oc-become-part-header {
  @include font-size(3.75rem);
}