$oc-header-waves-top: 140px;
$oc-header-waves-margin-right: 150px;
$oc-header-waves-breakpoint-to-mobile: 1280px;

.oc-navbar.oc-navbar-transparent {
  position: relative;
  z-index: 1000;
  background: transparent;
  margin: - $oc-header-waves-top 0;
}

body.oc-page-with-carousel, body.pagets__start {
  margin-top: $oc-header-waves-top;
}

@keyframes move-wave {
  0% {
    background-position: left -1920px;
  }
  100% {
    background-position: left 0;
  }
}

.oc-header-waves-wrapper {
  background: #69bcc0;
}

.oc-header-waves {
  position: relative;
  height: 800px;
  max-height: 800px;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
}

.oc-header-waves-logo {
  position: absolute;
  z-index: 999;
  top: 26px;
  left: $oc-header-waves-margin-right;
}

.oc-header-waves-wave {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
}

.oc-header-waves-wave.white {
  z-index: 20;
  background: url(../Images/header-wave-white.png) no-repeat;
}

.oc-header-waves-wave.green {
  z-index: 30;
  background: url(../Images/header-wave-green.png) no-repeat;
  opacity: 0.5;
  animation: move-wave 16s linear infinite;
}

.oc-header-waves-wave.blue {
  z-index: 40;
  background: url(../Images/header-wave-blue.png) no-repeat;
  opacity: 0.5;
  animation: move-wave 32s linear infinite;
}

.oc-header-waves-slider-image-shadow-top {
  position: absolute;
  z-index: 19;
  background: linear-gradient(rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 40%);
  height: 100%;
  width: 100%;
}

.oc-header-waves-slider-content {
  position: absolute;
  z-index: 100;
  width: 450px;
  bottom: rem-calc(4);
  left: $oc-header-waves-margin-right;

  h1 {
    font-weight: 600;
    text-transform: uppercase;
    @include font-size(5rem);
    line-height: 5.2rem;
  }

  span {
    font-weight: 300;
    text-transform: uppercase;
  }

  p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1280px) {

  .oc-header-waves {
    max-height: 530px;

    .white, .green, .blue {
      display: none;
    }
  }

  .oc-header-waves-slider-image-shadow-top {
    display: none;
  }

  .oc-header-waves-slider-image-shadow-bottom {
    display: block;
    position: absolute;
    top: 230px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    height: 300px;
    width: 100%;
  }

  .oc-header-waves-logo {
    background: url(../Images/header-waves-mobile-bg.png) no-repeat top left;
    padding: 1.75rem 0 0 1.75rem;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    img {
      width: 190px;
    }
  }
  .oc-header-waves-slider-content {
    color: #fff;
    width: 100%;
    bottom: 20rem;
    left: inherit;
    text-align: center;
    padding: 0 0.5rem;
  }

  .carousel-indicators {
    position: absolute;
    top: 480px;
    left: 0;
    right: 0;
    z-index: 1001;

    li {
      background-color: #fff
    }
  }
}