.oc-tabs {
  ul.nav-tabs {
    margin: 0 0 2rem 0;
  }

  .nav-tabs,
  .nav-link.active,
  .nav-item.show .nav-link {
    border: none;

  }

  .nav-tabs .nav-link {
    border: none;
  }

  .nav-tabs {
    .nav-link {
      border-radius: 0;
      font-weight: 600;
      font-size: 1.2rem;
      background-color: $oc-grey-lightest;
      color: $body-color;

      a {
        color: $body-color;
      }

      &.active {
        background-color: $oc-blue;
        color: #fff;
      }
    }
  }

  .nav-item {
    position: relative;
  }

  @include media-breakpoint-down(xs) {
    .nav-tabs .nav-item {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

.nav-link.active {
  .oc-triangle-tabs {
    $oc-triangle-tab-size: rem-calc(0.5);

    &:after {
      content: " ";
      position: absolute;
      left: 40%;
      bottom: -$oc-triangle-tab-size;
      border-top: $oc-triangle-tab-size solid $oc-blue;
      border-right: $oc-triangle-tab-size solid transparent;
      border-left: $oc-triangle-tab-size solid transparent;
      border-bottom: none;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}