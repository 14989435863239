$extra-small: 1rem;
$small: 2rem;
$medium: 4rem;
$large: 8rem;
$extra-large: 16rem;

.oc-space-before {
  &.extra-small {
    margin-top: $extra-small;
  }

  &.small {
    margin-top: $small;
  }

  &.medium {
    margin-top: $medium;
  }

  &.large {
    margin-top: $large;
  }

  &.extra-large {
    margin-top: $extra-large;
  }
}

.oc-space-after {
  &.extra-small {
    margin-bottom: $extra-small;
  }

  &.small {
    margin-bottom: $small;
  }

  &.medium {
    margin-bottom: $medium;
  }

  &.large {
    margin-bottom: $large;
  }

  &.extra-large {
    margin-bottom: $extra-large;
  }
}