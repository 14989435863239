.oc-testimonial {
  background: $oc-grey-lightest;
  &.blue {
    .oc-fa {
      color: $oc-blue-lighter;
    }
  }

  &.green {
    .oc-fa {
      color: $primary;
    }
  }

  .oc-fa {

    opacity: 0.7;
    z-index: 99;
  }

  $oc-quote-size: 75px;

  .oc-quote-icon {
    width: $oc-quote-size;
    height: $oc-quote-size;
  }

  .oc-quote-icon-right {
    position: absolute;
    bottom: 1.2rem;
    left: rem-calc(2);
  }

  .oc-quote-icon-left {
    position: absolute;
    top: 0.5rem;
    right: rem-calc(2);
  }
}

.oc-testimonial-header {
  min-height: 90px;
}

.oc-testimonial-text {
  font-style: italic;
}