.tx-indexedsearch-redMarkup {
  color: red;
}

.tx-indexedsearch-info-sword, .tx-indexedsearch-browsebox {
  font-style: italic;
}

.tx-indexedsearch-browsebox {
  p {
    margin: 0;
    padding: 0;
  }
}

ul.tx-indexedsearch-browsebox {
  margin: 0;
  padding: 0;

  li {
    margin: 1rem 0;
    padding-right: 1rem;
    list-style: none;
    float: left;
  }
}