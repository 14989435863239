.oc-event-search-form {
  background: $oc-grey-lightest;

  input, select {
    margin-bottom: 0.5rem;
  }

  .oc-button-search {
    width: 100%;
    padding: 0.4rem;
  }
}

.oc-search-form-check {
  input {
    margin-top: 0.5rem;
    width: 25px;
    height: 25px;
  }

  label {
    margin: 0.5rem 0 0 1rem;
  }
}

.oc-event-search-result-item {
  h2 {
    color: $body-color;
    font-size: 1.875rem;
  }
  &.even {
    .oc-event-search-result-item-date, .oc-event-search-result-item-address {
      background: $primary;
    }
  }

  &.odd {
    .oc-event-search-result-item-date, .oc-event-search-result-item-address {
      background: $oc-blue;
    }
  }
}

.oc-event-search-result-item-date, .oc-event-search-result-item-address {

  color: #fff;
}

.oc-event-search-result-item-date__day {
  font-size: 3.75rem;
}

.oc-event-search-result-item-date__monthyear {
  text-transform: uppercase;
  line-height: 1.75rem;
  font-size: 1.5625rem;
}

.oc-event-search-result-item-text {
  background: $oc-grey-light;
}

.oc-hidemap-1 {
  display: none;
}