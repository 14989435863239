footer.oc-page-footer {
  min-height: 900px;
  padding-top: 340px;
  background: url(../Images/footer-bg.png) no-repeat top left;
  color: #fff;

  a {
    color: #fff;
  }

  hr {
    border-top: 2px solid #fff;
    margin: rem-calc(5 0);
  }

  @include media-breakpoint-down(md) {
    padding-top: rem-calc(16);
  }
}

.oc-footer-intro {
  font-weight: 500;
  font-size: 1.5625rem;
}

.oc-footer-newsletter-form {
  input {
    padding: 25px;
    border: none;

    &::placeholder {
      color: #fff;
      text-align: center;
    }

    background: $oc-blue-lighter;
    border-radius: 0;
  }

  .oc-input-spacing {
    margin-right: 10px;
  }

  input {
    margin-bottom: 5px;
  }

  .btn-light {
    padding: 9px 0;
    width: 100%;
    color: #4CACE3;
    font-weight: 600;

    &:hover {
      color: #fff;
      background: $oc-green2;
    }
  }

  @include media-breakpoint-down(sm) {
    .oc-input-spacing {
      margin-right: 0;
    }
  }
}

.oc-footer-search-form {
  float: right;
  width: rem-calc(20);
  margin-top: 10px;

  input {
    padding: 25px;
    border: none;

    &::placeholder {
      color: $oc-blue;
      font-weight: 500;
    }
  }

}

.oc-footer-search-form-input-wrapper {
  position: relative;
  i {
    color: $oc-blue;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 0 .5rem;
  }

}

.oc-footer-content {
  font-weight: 500;
  font-size: 1.2rem;
}

.oc-footer-nav {
  text-transform: uppercase;
  margin-right: -1rem;
  float: right;

  .nav-link {
    padding: 0.5rem;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.oc-footer-logo {
  margin-bottom: rem-calc(3.5);
}

.oc-footer-address {
  margin-bottom: rem-calc(3.5);
}

.oc-footer-socialicons {
  float: right;
  @include media-breakpoint-down(md) {
    float: none;
  }

  a {
    margin-right: 10px;
  }
}

.oc-footer-padding {
  padding-bottom: rem-calc(3);
}

.oc-footer-white {
  padding-top: 25px;
  font-size: 1.25rem;
  background: #fff;
  color: $oc-blue;

  p {
    padding: 0;
    @include media-breakpoint-down(md) {
      text-align: center !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .oc-footer-logo, .oc-footer-address, .oc-footer-socialicons {
    text-align: center;
  }
  .oc-footer-address, .oc-footer-socialicons {
    border-bottom: 2px solid #fff;
    padding-bottom: rem-calc(3.5);
  }
  .nav.oc-footer-nav {
    margin: rem-calc(3.2 0);
    display: block;
    text-align: center;
  }
  .oc-footer-nav, .oc-footer-search-form {
    float: inherit;
    margin: 0 auto;
    max-width: 90%;
  }
}

#mc_embed_signup div.mce_inline_error {
  background-color: #ff8000 !important;
  font-weight: 300 !important;
  font-style: italic;
}

#mce-responses, #mce-responses a {
  color: darkred !important;
}

.oceans-flag {
  position: relative;
  top: -2px;
}
